<template>
  <a-spin :spinning="spinShow">
    <div class="form-search-body" style="padding:0 20px;">
      <a-list :bordered="false" :dataSource="dataSource" style="font-size: 13px">
        <a-list-item style="cursor: pointer" class=" default-hover"
                     slot="renderItem"
                     @click.native="itemClick(item)"
                     slot-scope="item">
          <a-col :span="2">
            <a-icon type="user"/>
          </a-col>
          <a-col :span="22"> {{ item.driver.name }}（{{ item.driver.mobilePhoneNumber }}）</a-col>
        </a-list-item>
        <div slot="footer">
          <a-pagination size="small"
                        :current="pagination.current"
                        :pageSize="pagination.pageSize"
                        :total="pagination.total"
                        :showTotal="pagination.showTotal"
                        @change="onPageChange"/>
        </div>
      </a-list>
    </div>
  </a-spin>
</template>

<script>
  import SERVICE_URLS from '@/api/service.url'
  import searchListCommon from '../mixins/search-list-common'

  export default {
    name: 'SearchDriverList',
    props: {},
    mixins: [searchListCommon],
    data () {
      return {
        searchParams: {
          number: '0',
          size: '10',
          direction: '',
          order: '',
          driverName: ''
        },
        pagination: {
          current: 1,
          total: 1,
          pageSize: 10,
          showSizeChanger: true,
          showTotal: (total, range) => `第 ${range[0]}-${range[1]} 条 共${total}记录`
        },
        dataSource: [],
        listType: 'driver'
      }
    },
    methods: {
      /**
       * 执行数据加载
       */
      loadData () {
        if (this.spinShow) return
        this.$emit('onSearchStart')
        this.$http(this, {
          url: SERVICE_URLS.csgz.carConfigApi.overrideSearch,
          data: this.searchParams,
          noTips: true,
          loading: 'spinShow',
          success: (data) => {
            this.dataSource = data.body.content
            this.pagination.total = data.body.totalElements
            this.pagination.current = data.body.number + 1
            this.pagination.pageSize = data.body.size
            this.searchParams.size = data.body.size
            this.searchParams.number = data.body.number
            this.$emit('onSearchSuccess')
          },
          error: () => {
            this.$emit('onSearchError')
            this.$message.error('司机检索失败！')
          }
        })
      },
      /*分页改变*/
      onPageChange (page, pageSize) {
        this.searchParams.number = page - 1
        this.searchParams.size = pageSize
        this.loadData()
      },
      /**
       * 重置分页信息
       */
      resetPage () {
        this.searchParams.number = 0
      },

      /**
       * searchKey发生变化 watch会执行当前方法
       * @param value 变化值
       */
      searchKeyChange (value) {
        console.info('??????????', value)
        this.searchParams.driverName = value
      },

      /**
       * 列表点击事件
       * @param item 当前列值
       */
      itemClick (item) {
        this.$emit('onItemClick', this.listType, item)
      }
    }
  }
</script>
