<template>
  <div class="metrics-box center">
    <span class="font-size-15">{{value}} {{unit}}</span>
    <div class="metrics-box-child">
      <slot name="title">
        <span>{{name}}</span>
      </slot>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Metrics',
    props: {
      value: {
        type: [String, Number],
        default: ''
      },
      unit: {
        type: String,
        default: ''
      },
      name: {
        type: [String, Number],
        default: ''
      }
    },
    watch: {
      value (val) {
        this.value = val
      },
      unit (val) {
        this.unit = val
      },
      name (val) {
        this.name = val
      }
    },
    data() {
      return {}
    },
    methods: {
    }
  }
</script>
<style scoped>
  .metrics-box {
    height: 71px;
    margin: 0px 6px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(45, 140, 240);
    border-radius: 10px;
    font-style: normal;
    font-family: '微软雅黑 Bold', '微软雅黑';
    font-weight: 700;
    color: #2d8cf0;
  }

  .metrics-box-child {
    width: 117px;
    height: 29px;
    margin: 11px auto;
    padding-top: 5px;
    background-color: rgb(45, 140, 240);
    border: none;
    border-radius: 0px 0px 15px 15px;
    color: #FFFFFF;
  }
</style>