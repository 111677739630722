import SERVICE_URLS from '@/api/service.url'

const area = {
  data () {
    return {
      areaDataList: [],
      areaMarkerOverLays: [],
      areaMarkerOverLayMap: {},
      districtOverlayGroups: {}
    }
  },
  methods: {
    /*初始化行政区划信息*/
    loadAreaList () {
      this.$http(this, {
        url: SERVICE_URLS.csgz.areaDictionaryApi.tree,
        noTips: true,
        success: (data) => {
          this.areaDataList = data.body
          this.areaMarkerOverLays = []
          this.addAreaDataToMap()
        }
      })
    },
    addAreaDataToMap () {
      if (!this.map) return
      const areaGroupOverLay = []
      const townGroupOverLay = []
      const villageGroupOverLay = []
      this.areaDataList.forEach((x) => {
        if (this.validCoordinates(x)) {
          let marker = new AMap.Marker({
            position: new AMap.LngLat(x.longitude, x.latitude),
            offset: new AMap.Pixel(0, 0),
            title: x.name,
            icon: this.getMarkerIcon(x),
            zoom: 15,
            zIndex: 180,
            extData: {
              key: x
            }
          })
          let  offSet = 20;
          marker.on('click', this.showAreaInfo)
          if (x.code.slice(-4) === '0000') {
            areaGroupOverLay.push(marker)
            offSet = 0
          } else if (x.code.slice(-2) === '00' && x.code.slice(-4) !== '0000') {
            townGroupOverLay.push(marker)
            offSet = 24
          } else if (x.code.slice(-2) !== '00') {
            offSet = 20
            villageGroupOverLay.push(marker)
          }
          marker.setLabel({
            offset: new AMap.Pixel(offSet, 0), //设置文本标注偏移量
            content: '<div class="car-marker">' + x.name + '</div>', //设置文本标注内容
            direction: 'right' //设置文本标注方位
          })
          this.areaMarkerOverLayMap[x.code] = x
        }
      })
      this.districtOverlayGroups.area = new AMap.OverlayGroup([areaGroupOverLay])
      this.districtOverlayGroups.town = new AMap.OverlayGroup([townGroupOverLay])
      this.districtOverlayGroups.village = new AMap.OverlayGroup([villageGroupOverLay])
      for (let i in this.districtOverlayGroups) {
        this.districtOverlayGroups[i].setMap(this.map)
        this.districtOverlayGroups[i].hide()
      }
      this.districtOverlayGroups.area.show()
    },
    validCoordinates (x) {
      let ret = false
      if (x.longitude && x.latitude && x.longitude !== '' && x.latitude !== '') {
        ret = true
      }
      return ret
    },
    showAreaInfo (e) {
      this.dropdpwnVisible = false
      this.areaTreeVisible = false
      const item = e.target.getExtData()['key']
      this.rightFormChecked = 'area'
      this.rightFormShow = true
      this.$nextTick(() => {
        this.getRightDetailRef().loadAreaInfo(item)
      })
      this.setSelectedMarkerStyle([item.longitude, item.latitude])
    },
    loadAreaInfoFromLayer (areaCode) {
      if (!this.map) return
      if (areaCode === '0') {
        this.map.setFitView()
      } else {
        let zoomLevel = 16
        let tempCode = areaCode
        if (areaCode.length === 2) { //县
          tempCode = areaCode + '0000'
          this.map.setFitView()
        } else if (areaCode.length === 4) { //乡
          tempCode = areaCode + '00'
          zoomLevel = 14
          this.map.setZoom(zoomLevel)
        } else {
          this.map.setZoom(zoomLevel)
        }
        const item = this.areaMarkerOverLayMap[tempCode]
        if (item) {
          let coord = [item.longitude, item.latitude]
          this.setSelectedMarkerStyle(coord)
          this.map.setCenter(coord)
          this.rightFormChecked = 'area'
          this.rightFormShow = true
          this.$nextTick(() => {
            this.getRightDetailRef().loadAreaInfo(item)
          })
        }

      }
    },
    getMarkerIcon (x) {
      let areaNo = x.code
      let widthHeight = 20
      let imageUrl = 'http://assets.boze-tech.com/cun.png'
      if (this.endWith(areaNo, '0000')) { //县
        widthHeight = 30
        imageUrl = 'http://assets.boze-tech.com/xian.png'
      } else if (this.endWith(areaNo, '00')) { //镇
        widthHeight = 26
        imageUrl = 'http://assets.boze-tech.com/zhen.png'
      }
      let icon = new AMap.Icon({
        size: new AMap.Size(widthHeight, widthHeight), // 图标尺寸
        image: imageUrl, // Icon的图像
        imageSize: new AMap.Size(widthHeight, widthHeight) // 根据所设置的大小拉伸或压缩图片
      })
      return icon
    },
    endWith (str, endStr) {
      let reg = new RegExp(endStr + '$')
      return reg.test(str)
    }
  }
}
export default area
