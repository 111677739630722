<template>
  <div>
    <a-tabs v-model="tabsKey" @change="callback">
      <a-tab-pane v-for="item in spotCount" :key="item.no" :tab="item.name+'('+item.total+')'"></a-tab-pane>
      <a-radio-group v-model="activeKey" button-style="solid" slot="tabBarExtraContent" @change="radioGroupChange">
        <a-radio-button value="ALL">
          全部
        </a-radio-button>
        <a-radio-button :value="spotTypeEnum.JIWUCHI.value">
          集污池
        </a-radio-button>
        <a-radio-button :value="spotTypeEnum.DANWENG.value">
          单翁
        </a-radio-button>
        <a-radio-button :value="spotTypeEnum.SANGE.value">
          三格化粪池
        </a-radio-button>
        <a-radio-button :value="spotTypeEnum.LANI.value">
          拉泥
        </a-radio-button>
        <a-radio-button :value="spotTypeEnum.GONGCE.value">
          公厕
        </a-radio-button>
        <a-radio-button :value="spotTypeEnum.OTHER.value">
          其他
        </a-radio-button>
      </a-radio-group>
    </a-tabs>
    <a-row :gutter="32" style="margin-top: 16px;">
      <a-col :sm="18" :md="18" :lg="18" :xl="18">
        <div style="margin-left: 14px">当前区域: {{areaName}} &nbsp; &nbsp;&nbsp;&nbsp; {{townName}}</div>
        <div id="loopHistory"></div>
      </a-col>
      <a-col :sm="6" :md="6" :lg="6" :xl="68">
        <div style="height: 280px;overflow: auto;padding-right: 10px;">
          <rank-list title="清运排行榜" :list="rankList"/>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
  import RankList from '@/components/chart/RankList'
  import G2 from '@antv/g2'
  import SERVICE_URLS from '@/api/service.url'
  import { spotTypeEnum } from '@/constant/spot/spotType'

  export default {
    components: { RankList },
    name: 'spotStatistic',
    data () {
      return {
        rankList: [],
        yName: 'total',
        areaCode: null,
        type: null,
        spotCount: [],
        data: [],
        chart: null,
        activeKey: 'ALL',
        townName: '',
        areaName: '',
        tabsKey: '',
        spotTypeEnum: spotTypeEnum
      }
    },
    mounted () {
      this.initChart()
      this.search()
    },
    methods: {
      callback (key) {
        this.tabsKey = key
        const tab = this.spotCount.find((e) => e.no === key)
        this.areaName = tab.name
        console.log(tab)
        this.areaCode = key.substring(0, 2)
        this.search()
      },
      radioGroupChange (e) {
        if (e.target.value === 'ALL') {
          this.type = null
        } else {
          this.type = e.target.value
        }
        this.search()
      },
      search () {
        this.$http(this, {
          url: SERVICE_URLS.csgz.gatherSpot.gather_spot_statistic,
          data: {
            areaCode: this.areaCode,
            type: this.type
          },
          noTips: true,
          loading: 'spinShow',
          success: (data) => {
            if (data.body.spotCount) {
              this.spotCount = data.body.spotCount
              if (this.tabsKey === '') {
                this.tabsKey = this.spotCount[0].no
                this.areaName = this.spotCount[0].name
              }
            }
            this.data = data.body.echartData
            this.chart.source(this.data)
            this.chart.repaint()
            this.getSortData()
          }, error: (data) => {
          }
        })
      },
      getSortData () {
        //排序
        this.data.sort(function (a, b) {
          return b.total - a.total
        })
        this.rankList = this.data
      },
      initChart () {
        const chart = new G2.Chart({
          container: 'loopHistory',
          forceFit: true,
          height: 300,
          padding: [50, 50, 50, 50]
        })
        this.setChartOption(chart)
        chart.source(this.data)
        chart.on('interval:click', ev => {
          const data = ev.data
          if (data) {
            console.log(this.areaCode, data._origin.no)
            if (this.areaCode === null || this.areaCode.length === 2) {
              this.townName = data._origin.name
              this.areaCode = data._origin.no.substring(0, 4)
            } else {
              this.areaCode = this.areaCode.substring(0, 2)
              this.townName = ''
            }
            this.search()
          }

        })
        this.chart = chart
        this.chart.render()
      },
      setChartOption (chart) {
        chart.scale('total', {
          alias: '集污点数量',
          nice: true
        })
        chart.tooltip({
          showTitle: true
        })
        chart.intervalStack()
          .position('name*' + `${this.yName}`)
        chart.point().position('name*' + `${this.yName}`)
          .tooltip('name*' + `${this.yName}`)
          .label(`${this.yName}`, {
            formatter (text, item, index) {
              return text
            },
            content: (originData) => {
              return originData
            },
            offset: 20
          })
      }
    }
  }
</script>