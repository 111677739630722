<template>
  <div>
    <a-button @click="reportRecordClick">清运任务统计</a-button>
    <a-button @click="driverClick">司机清运统计</a-button>
    <a-button @click="carClick">车辆清运统计</a-button>
    <a-button @click="spotClick">集污点统计</a-button>
    <a-modal :centered="true" v-model="reportRecordModal" :footer="null" :width="bodyWidth">
      <report-statistics ref="reportRecordRef"></report-statistics>
    </a-modal>
    <a-modal :centered="true" v-model="driverModal" :footer="null" :width="bodyWidth" title="司机任务统计">
      <driver-statistics ref="driverStatisticsRef"></driver-statistics>
    </a-modal>
    <a-modal :centered="true" v-model="carModal" :footer="null" :width="bodyWidth" title="车辆任务统计">
      <car-statistics ref="carStatisticsRef"></car-statistics>
    </a-modal>
    <a-modal :centered="true" v-model="spotModal" :footer="null" :width="bodyWidth" title="集污点统计">
      <spot-statistics ref="carStatisticsRef"></spot-statistics>
    </a-modal>
  </div>
</template>
<script>
  import { mapState } from 'vuex'
  import ReportStatistics from './ReportStatistics'
  import DriverStatistics from './DriverStatistics'
  import CarStatistics from './CarStatistics'
  import SpotStatistics from './SpotStatistics'

  export default {
    name: 'Statistics',
    mixins: [],
    props: {},
    components: { ReportStatistics, DriverStatistics, CarStatistics, SpotStatistics },
    data () {
      return {
        reportRecordModal: false,
        driverModal: false,
        carModal: false,
        spotModal: false
      }
    },
    computed: {
      ...mapState({
        bodyWidth: (state) => {
          const width = state.app.bodyWidth
          return width > 1400 ? 1400 : width
        }
      })
    },
    methods: {
      reportRecordClick () {
        this.reportRecordModal = true
      },
      driverClick () {
        this.driverModal = true
      },
      carClick () {
        this.carModal = true
      },
      spotClick () {
        this.spotModal = true
      }
    }
  }
</script>
<style scoped>
  button {
    margin-left: 10px;
    box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.349019607843137);
    color: black;
  }
</style>

