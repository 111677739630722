<template>
  <div>
    <div class="fs-search-box">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-form-item label="上报日期" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-range-picker style="width: 260px;"
                            format="YYYY-MM-DD" v-model="dateSearch"
                            @change="handleModalPickerOk"></a-range-picker>
          </a-form-item>
          <a-form-item label="上报次数" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-statistic :value="pagination.total" style="width: 260px;">
              <template #suffix>
                <span>次</span>
              </template>
            </a-statistic>
          </a-form-item>
          <a-form-item class="f-r m-r-none">
            <a-button type="primary" @click="search" icon="search">查询</a-button>
            <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <a-table
      size="middle"
      :rowKey="(record) => record.id"
      @change="tableChange"
      :pagination="pagination"
      :columns="columns"
      :dataSource="tableData"
      :scroll="{ x: 1000 }">
      <template slot="_index" slot-scope="text, record, index">
        {{ searchParams.number * searchParams.size + index + 1 }}
      </template>
      <template slot="name" slot-scope="text, record">
        <a-icon v-if="record.openId" style="color:#2ba245" type="wechat" theme="filled"/>&nbsp;<a-icon
        v-if="record.ifUrgent" style="color:#ff644e" type="fire" theme="filled"/>&nbsp;&nbsp;&nbsp;{{record.spot?record.spot.name:''}}
      </template>
      <template slot="type" slot-scope="text, record">
        <div v-if="record.type==='TEL'">
          电话
        </div>
        <div v-if="record.type==='WECHAT'">
          微信
        </div>
        <div v-if="record.type==='APP'">
          APP
        </div>
        <div v-if="record.type==='OTHER'">
          自主任务
        </div>
      </template>
      <template slot="fromAddress" slot-scope="text, record">
        <a-tooltip>
          <template slot="title">
            {{record.spot.houseNo !==null&&record.spot.houseNo.length>0 ? record.fromAddress+ '[' +
            record.spot.houseNo+']' :
            record.fromAddress}}
          </template>
          {{record.spot.houseNo !==null&&record.spot.houseNo.length>0 ? record.fromAddress+ '[' +
          record.spot.houseNo+']' :
          record.fromAddress}}
        </a-tooltip>
      </template>
      <template slot="handleStatus" slot-scope="text, record">
        <a-tag v-if="record.handleStatus==='SCHEDULED'" color="#33CC33">
          已调度
        </a-tag>
        <a-tag v-if="record.handleStatus==='PROCESSING'" color="#6666CC">
          处理中
        </a-tag>
        <a-tag v-if="record.handleStatus==='PROCESSED'" color="#CC6699">
          待回访
        </a-tag>
        <a-tag v-if="record.handleStatus==='FEEDBACK_COMPLETE'" color="#FF9900">
          待确认
        </a-tag>
        <a-tag v-if="record.handleStatus==='CONFIRM_COMPLETE'" color="#99CC33">
          已完成
        </a-tag>
      </template>
      <template slot="bindStatus" slot-scope="text, record">
        <a-tag v-if="record.bindStatus==='NOTOPEN'" color="red">
          未审核
        </a-tag>
        <a-tag v-if="record.bindStatus==='OPTIONAL'" color="blue">
          待接单
        </a-tag>
        <a-tag v-if="record.bindStatus==='CHECKED'" color="green">
          已接单
        </a-tag>
        <a-tag v-if="record.bindStatus==='DOING'" color="pink">
          清运中
        </a-tag>
        <a-tag v-if="record.bindStatus==='COMPLETE'" color="#CC9966">
          已清运
        </a-tag>
      </template>
    </a-table>
  </div>
</template>
<script>
  import entityCRUD from '../../../common/mixins/entityCRUD'
  import { gatherSpotFormVO } from '../../gatherspot/common/common'
  import { myReportColumns } from '../../reportrecord/report/common/common'
  import SERVICE_URLS from '@/api/service.url'

  export default {
    name: 'SpotHistoryList', props: {
      // 需要显示在地图中的自定义标注物
      spotNo: {
        type: String,
        default: undefined
      },
    },
    mixins: [entityCRUD],
    data() {
      return {
        dateSearch: null,
        labelCol: { span: 7 },
        wrapperCol: { span: 17 },
        formItem: gatherSpotFormVO(),
        spinShow: false,
        searchContent: 'searchNo',
        entityBaseUrl: SERVICE_URLS.csgz.reportRecord,
        searchUrl: SERVICE_URLS.csgz.reportRecord.search,
        searchParams: {
          number: '0',
          size: '10',
          direction: '',
          order: '',
          fromPhone: '',
          idcard: '',
          no: '',
          startFromTime: '',
          endFromTime: ''
        },
        initColumns: myReportColumns(),
        tableData: [],
        myReportRecordId: null,
        pagination: {
          current: 1,
          total: 1,
          pageSize: 10,
          showSizeChanger: true,
          showTotal: (total, range) => `第 ${range[0]}-${range[1]} 条 共${total}条记录`
        }
      }
    },
    methods: {
      search() {
        if (this.spotNo) {
          this.searchParams.no = this.spotNo
        }
        this.$http(this, {
          url: SERVICE_URLS.csgz.reportRecord.search,
          noTips: true,
          loading: 'tableLoading',
          data: this.searchParams,
          success: (data) => {
            this.tableData = data.body.content
            this.pagination.total = data.body.totalElements
            this.pagination.current = data.body.number + 1
            this.pagination.pageSize = data.body.size
            this.searchParams.size = data.body.size
            this.searchParams.page = data.body.number
          }
        })
      },
      //抽屉关闭后清理选择日期
      resetForm () {
        this.dateSearch = null
        this.searchParams.startFromTime = ''
        this.searchParams.endFromTime = ''
        this.search()
      },
      handleModalPickerOk (value) {
        if (value.length > 0) {
          this.searchParams.startFromTime = value[0].format('YYYY-MM-DD')
          this.searchParams.endFromTime = value[1].format('YYYY-MM-DD')
        } else {
          this.searchParams.startFromTime = ''
          this.searchParams.endFromTime = ''
        }
        this.search()
      },
    }
  }
</script>

<style scoped>

</style>