<template>
  <div class="form-container" v-if="visible">
    <div class="form-container-header">
      <button type="button" class="ant-modal-close" @click="closeHandle()"><span class="ant-modal-close-x"><a-icon
        type="close"></a-icon></span></button>
      <div class="ant-modal-title">
        <slot name="title">
          <span>{{titleName}}</span>
        </slot>
      </div>
    </div>
    <div class="form-container-body">
      <slot></slot>
    </div>
  </div>
</template>

<script>

  import containerClose from '../mixins/container-close'

  export default {
    name: 'RightFormContainer',
    mixins: [containerClose],
    props: {
      title: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        titleName: this.title
      }
    },
    watch: {
      title (val) {
        this.titleName = val
      }
    }
  }
</script>