<template>
  <div style="width: 100%; height: 100%;">
    <a-row>
      <a-col :span="8">
        <a-card>
          <a-card-grid style="width:100%;">
            <a-row>
              <a-range-picker :allowClear="false" class="m-r-12" @change="dateChange" v-model="rangeTime" style="width: 218px;"
                              format="YYYY-MM-DD">
                <a-icon slot="suffixIcon" type="calendar"/>
              </a-range-picker>
              <a-radio-group @change="selectDateOption" button-style="solid" v-model="dateChoose" style="float: right;">
                <a-radio-button value="today">今天</a-radio-button>
                <a-radio-button value="yesterday">昨天</a-radio-button>
                <a-radio-button value="week">本周</a-radio-button>
                <a-radio-button value="preWeek">上周</a-radio-button>
              </a-radio-group>
            </a-row>
            <a-row style="margin-top: 16px;">
              <a-tooltip>
                <template slot="title">
                  {{showCar? '点击切换司机选择' : '点击切换车辆选择'}}
                </template>
                <a-button class="m-r-12" icon="swap" @click="changeType">{{showCar? '车 辆' : '司 机'}}
                </a-button>
              </a-tooltip>
              <a-select class="m-r-12" v-if="showCar" style="width: 240px"
                        @change="selectCarInfo"
                        v-model="searchParams.carId"
                        placeholder="请选择车辆">
                <a-select-option
                  v-for="item in carList"
                  :key="item.id"
                  :value="item.id">
                  {{ getItemName(item) }}
                </a-select-option>
              </a-select>
              <a-tree-select class="m-r-12"
                             v-if="!showCar"
                             style="width: 240px"
                             v-decorator="[
                              'driver.id',
                              {
                                validateTrigger: ['change'],
                              }
                            ]"
                             @select="driverSelect"
                             treeDefaultExpandAll
                             treeNodeFilterProp="title"
                             :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
                             :treeData="orgUserTreeData"
                             placeholder="请选择司机"/>
              <a-button @click="dateSearch" style="float: right" :loading="loading" icon="search">查询</a-button>
            </a-row>
          </a-card-grid>
          <a-card-grid style="width:25%;text-align:center;">
            <a-row>清运吨数</a-row>
            <a-row><span style="font-size: larger;font-weight: bold;line-height: 30px;">{{ statisticsData.tons || '0' }}</span>&nbsp;吨
            </a-row>
          </a-card-grid>
          <a-card-grid style="width:25%;text-align:center">
            <a-row>清运公里</a-row>
            <a-row><span style="font-size: larger;font-weight: bold;line-height: 30px;">{{ statisticsData.mileages || '0' }}</span>&nbsp;KM
            </a-row>
          </a-card-grid>
          <a-card-grid style="width:25%;text-align:center">
            <a-row>清运次数</a-row>
            <a-row><span style="font-size: larger;font-weight: bold;line-height: 30px;">{{ statisticsData.records || '0' }}</span>&nbsp;趟
            </a-row>
          </a-card-grid>
          <a-card-grid style="width:25%;text-align:center">
            <a-row>清运时长</a-row>
            <a-row><span style="font-size: larger;font-weight: bold;line-height: 30px;">{{ statisticsData.durations || '0' }}</span>&nbsp;
            </a-row>
          </a-card-grid>
          <a-card-grid :style="{'height': bodyHeight-300 + 'px','width': '100%','overflow-y': 'auto'}">
            <a-list style="height: 100%;" :dataSource="listData"
                    item-layout="vertical">
              <a-list-item slot="renderItem"
                           @click="loadDetailTrail(item.carInfo.id, item.id)" slot-scope="item, index">
                <a-row style="margin-bottom: 10px">
                  <a-col :span="24">
                    <a-space size="small">
                      <a-avatar size="small" style="background-color: rgb(145,155,174);">
                        {{pagination.pageSize*(pagination.current-1)+index+1}}
                      </a-avatar>
                      <div>
                        {{item.driver.name}}在{{item.startTime}}~{{item.endTime}}（<span class="key-value">{{item.durationStr}}</span>），驾驶{{item.carInfo.fullName}}清运车，<span
                        v-if="item.status==='DOING'">清运执行中</span><span v-if="item.status==='COMPLETED'">行驶<span
                        class="key-value">{{item.mileage}}</span>公里，已清运完成</span>。
                      </div>
                    </a-space>
                  </a-col>
                </a-row>
                <a-row style="margin-bottom: 6px;padding-left: 26px;">
                  <a-tag v-for="i in item.reportNames" :key="i" color="blue"
                         style="margin-right: 3px;margin-left: 3px;margin-bottom: 6px;">{{i}}
                  </a-tag>
                </a-row>
                <a-row style="padding-left: 26px;">
                  <a-col :span="10" style="">清运吨数：<span class="key-value">{{item.carInfo.volume}}</span> 吨</a-col>
                  <a-col :span="14" style="text-align: right">排放地点：<span class="key-value">{{item.destination}}</span>
                  </a-col>
                </a-row>
              </a-list-item>
            </a-list>
          </a-card-grid>
          <a-card-grid style="width:100%;">
            <a-pagination size="small"
                          :current="pagination.current"
                          :pageSize="pagination.pageSize"
                          :total="pagination.total"
                          :showTotal="pagination.showTotal"
                          @change="onPageChange"/>
          </a-card-grid>
        </a-card>
      </a-col>
      <a-col :span="16">
        <div :id="detailMapContainerId"
             :style="{'height': bodyHeight + 'px','width': '100%','overflow-y': 'auto'}">
        </div>
        <div style="position:absolute;bottom: 40px;margin-left:40px;z-index: 100;" v-if="showCar">
          <span style="font-size: xx-large;font-weight: bolder;color: #2b97ff;">行驶总里程：&nbsp;&nbsp;{{mileage}}&nbsp;&nbsp;KM</span>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
  import SERVICE_URLS from '@/api/service.url'
  import UUID from '../../../../utils/UUID'
  import { lazyAMapApiLoaderInstance } from 'vue-amap'
  import { mapState } from 'vuex'
  import mapInit from '../../../map/mixins/map-init'
  import mapStyle from '../../../map/composite/mixins/map-style'
  import moment from 'moment'

  export default {
    props: {},
    name: 'HistoryTrack',
    mixins: [mapInit, mapStyle],
    data () {
      return {
        entityBaseUrl: SERVICE_URLS.csgz.carLoopRecordApi,
        listData: [],
        dateChoose: 'yesterday',
        //统计信息
        statisticsData: {
          records: 0,
          mileages: 0,
          durations: 0,
          tons: 0
        }, //统计信息
        searchParams: {
          number: '0',
          size: '10',
          direction: '',
          order: '',
          driverId: null,
          startTime: '',
          endTime: '',
          carId: null,
          carInfoId: null
        },
        mileage: 0,
        carList: [],
        rangeTime: [],
        loading: false,
        showCar: true,
        orgUserTreeData: [],
        tempDriverId: null,
        /*地图*/
        detailMapContainerId: new UUID().id,
        detailMap: null,
        marker: null,
        pathArray: null,
        /*分页*/
        pagination: {
          current: 1,
          total: 1,
          pageSize: 10,
          showSizeChanger: true,
          showTotal: (total, range) => `第 ${range[0]}-${range[1]} 条 共${total}记录`
        },
        startMarker: '',
        endMarker: '',
        carStopMarks: [],
        sewagePlantOverlays: [],
        sewagePlantOverlayGroup: undefined,
        loopRecordPolyline: undefined
      }
    },
    computed: {
      ...mapState({
        bodyHeight: (state) => {
          return state.app.bodyHeight
        }
      })
    },
    mounted () {
      this.searchParams.startTime = moment().subtract(1, 'days').format('YYYY-MM-DD')
      this.searchParams.endTime = moment().subtract(1, 'day').format('YYYY-MM-DD')
      this.rangeTime = [this.searchParams.startTime, this.searchParams.endTime]
      this.loadCarInfoList()
      this.loadUserList()
    },
    methods: {
      init () {
        lazyAMapApiLoaderInstance.load().then(() => {
          this.detailMap = new AMap.Map(this.detailMapContainerId, {
            resizeEnable: true,
            mapStyle: 'amap://styles/normal',
            center: [106.230909, 38.487193],
            zoom: 11
          })
        })
      },
      /*初始化加载所有污水处理厂信息*/
      getSewagePlant () {
        this.$http(this, {
          url: SERVICE_URLS.csgz.sewagePlantApi.select,
          data: {},
          loading: 'no',
          noTips: true,
          success: (data) => {
            this.buildSewagePlant(data)
          },
          error: () => {
          }
        })
      },
      buildSewagePlant (data) {
        this.buildSewagePlantMarker(data.body)
        if (!this.detailMap) return
        this.detailMap.add(this.sewagePlantOverlays)
      },
      /*污水厂图标标记*/
      buildSewagePlantMarker (list) {
        this.sewagePlantOverlays = []
        list.forEach((x) => {
          // 创建 AMap.Icon 实例：
          var icon = new AMap.Icon({
            size: new AMap.Size(30, 30), // 图标尺寸
            image: 'https://sosfile.fangshuoit.com/o_1ec258ru2rtq1q69fl51oki112i1t.png', // Icon的图像
            imageSize: new AMap.Size(30, 30) // 根据所设置的大小拉伸或压缩图片
          })
          // 将 Icon 实例添加到 marker 上:
          var marker = new AMap.Marker({
            position: new AMap.LngLat(x.longitude, x.latitude),
            offset: new AMap.Pixel(-30, -14),
            title: x.name,
            icon: icon,
            zoom: 15,
            extData: {
              key: x
            }
          })
          marker.setLabel({
            offset: new AMap.Pixel(0, 0), //设置文本标注偏移量
            content: '<div class="car-marker">' + x.name + '</div>', //设置文本标注内容
            direction: 'top' //设置文本标注方位
          })
          if ((x.longitude !== '0') && (x.latitude !== '0')) {
            // marker.on('click', this.showInfo)
            this.getSewagePlantOverlayGroup().addOverlay(marker)
            this.sewagePlantOverlays.push(marker)
          }
        })
        this.detailMap.setFitView()
      },
      getSewagePlantOverlayGroup () {
        if (this.sewagePlantOverlayGroup === undefined) {
          this.sewagePlantOverlayGroup = new AMap.OverlayGroup()
        }
        return this.sewagePlantOverlayGroup
      },
      changeType () {
        this.showCar = !this.showCar
        this.searchParams.carId = null
        this.searchParams.carInfoId = null
        this.searchParams.driverId = null
      },
      selectCarInfo (id) {
        this.searchParams.carId = id
        this.dateSearch()
      },
      selectDateOption (e) {
        var type = e.target.value
        if (type === 'today') {
          this.searchParams.startTime = moment().format('YYYY-MM-DD')
          this.searchParams.endTime = moment().format('YYYY-MM-DD')
        } else if (type === 'yesterday') {
          this.searchParams.startTime = moment().subtract(1, 'days').format('YYYY-MM-DD')
          this.searchParams.endTime = moment().subtract(1, 'days').format('YYYY-MM-DD')
        } else if (type === 'week') {
          this.searchParams.startTime = moment().startOf('week').format('YYYY-MM-DD')
          this.searchParams.endTime = moment().format('YYYY-MM-DD')
        } else if (type === 'preWeek') {
          this.searchParams.startTime = moment().week(moment().week() - 1).startOf('week').format('YYYY-MM-DD')
          this.searchParams.endTime = moment().week(moment().week() - 1).endOf('week').format('YYYY-MM-DD')
        }
        this.rangeTime = [this.searchParams.startTime, this.searchParams.endTime]
      },
      dateChange (date, dateString) {
        this.searchParams.startTime = dateString[0]
        this.searchParams.endTime = dateString[1]
      },
      loadCarInfoList () {
        this.$http(this, {
          url: SERVICE_URLS.csgz.carInfoApi.select,
          data: {
            useType: 'QY'
          },
          noTips: true,
          success: (data) => {
            this.carList = data.body
          }
        })
      },
      dateSearch () {
        if (this.detailMap != null) {
          this.detailMap.clearMap()
        }
        const startTimestamp = new Date(this.searchParams.startTime).getTime()
        const endTimestamp = new Date(this.searchParams.endTime).getTime()
        if ((endTimestamp - startTimestamp) > 7 * 24 * 60 * 60 * 1000) {
          this.$message.warning('查询范围不能超过一周！')
          return
        }
        if (this.searchParams.carId > 0 && this.showCar) {
          this.loadStatistics()
          this.loadListData()
          this.loadTimeGisTrail()
        } else if (this.showCar) {
          this.$message.warning('请选择车辆！')
        } else if (this.searchParams.driverId > 0 && !this.showCar) {
          this.loadStatistics()
          this.loadListData()
        } else if (!this.showCar) {
          this.$message.warning('请选择司机！')
        }
      },
      /*画车辆时间段线方法*/
      drawLine (id, path) {
        this.detailMap.clearMap()
        var polyline = new AMap.Polyline({
          path: eval(path),
          showDir: true,
          strokeColor: '#2d8cf0', //线颜色--蓝色
          //strokeOpacity: this.online ? 0 : 1,     //线透明度
          strokeOpacity: 1, //线透明度
          strokeWeight: 4, //线宽
          lineJoin: 'round', // 折线拐点连接处样式
          extData: {
            key: id
          }
        })
        this.detailMap.add(polyline)
        this.drowPoint(path, true)
      },
      /*画清运记录线方法*/
      drawLoopRecordLine (id, path) {
        this.detailMap.remove(this.startMarker)
        this.detailMap.remove(this.endMarker)
        this.detailMap.remove(this.carStopMarks)
        this.detailMap.remove(this.sewagePlantOverlays)
        if (this.loopRecordPolyline) {
          this.detailMap.remove(this.loopRecordPolyline)
        }
        this.loopRecordPolyline = new AMap.Polyline({
          path: eval(path),
          showDir: true,
          strokeColor: '#f0273a', //线颜色--蓝色
          strokeOpacity: 1, //线透明度
          strokeWeight: 4, //线宽
          lineJoin: 'round', // 折线拐点连接处样式
          extData: {
            key: id
          }
        })
        this.detailMap.add(this.loopRecordPolyline)
        this.drowPoint(path, false)
        this.handleRunTrail()
      },
      //随机获取颜色
      randomColor () {
        const colors = ['#F07760', '#f0273a', '#70f0c3', '#9FF088', '#47f0d0', '#5C5EF0', '#706A6F']
        var rand = Math.floor(Math.random() * colors.length)
        return colors[rand]
      },
      drowPoint (path, ifRemove) {
        var gisArray = eval(path)
        var startPoint = eval(gisArray[0])
        var endPoint = eval(gisArray[gisArray.length - 1])
        let startIcon = new AMap.Icon({
          // 图标尺寸
          size: new AMap.Size(25, 34),
          // 图标的取图地址
          image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
          // 图标所用图片大小
          imageSize: new AMap.Size(135, 40),
          // 图标取图偏移量
          imageOffset: new AMap.Pixel(-9, -3)
        })
        this.startMarker = new AMap.Marker({
          map: this.detailMap,
          icon: startIcon,
          offset: new AMap.Pixel(-11, -30),
          position: [startPoint[0], startPoint[1]]
        })
        let endIcon = new AMap.Icon({
          size: new AMap.Size(25, 34),
          image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
          imageSize: new AMap.Size(135, 40),
          imageOffset: new AMap.Pixel(-95, -3)
        })
        this.endMarker = new AMap.Marker({
          map: this.detailMap,
          icon: endIcon,
          offset: new AMap.Pixel(-13, -33),
          position: [endPoint[0], endPoint[1]]
        })
        /*回放标记*/
        this.pathArray = path
        if (ifRemove) {
          this.marker = new AMap.Marker({
            map: this.detailMap,
            position: [startPoint[0], startPoint[1]],
            icon: 'https://webapi.amap.com/images/car.png',
            offset: new AMap.Pixel(-26, -13),
            autoRotation: true,
            angle: -90
          })
          var passedPolyline = new AMap.Polyline({
            map: this.detailMap,
            strokeColor: '#AF5', //线颜色
            strokeWeight: 6, //线宽
            lineJoin: 'round' // 折线拐点连接处样式
          })
          this.marker.on('moving', (e) => {
            passedPolyline.setPath(e.passedPath)
          })
        }
        /*添加到地图*/
        this.detailMap.add(this.startMarker)
        this.detailMap.add(this.endMarker)
        this.detailMap.setFitView()
        if (ifRemove) {
          this.handleRunTrail()
        }
        this.getSewagePlant()
      },
      handleRunTrail () {
        this.marker.moveAlong(eval(this.pathArray), 5000)
      },
      getItemName (item) {
        let itemName = ''
        itemName += item.platNumber
        itemName += '-'
        itemName += item.status === 'FREE' ? '空闲中' : item.status === 'GOING' ? '执行中' : '离线'
        itemName += '-'
        if (item.offline === '1') {
          itemName += item.stillTimeStr ? '静止(' + item.stillTimeStr + ')' : '静止'
        } else if (item.offline === '2') {
          itemName += '行驶'
        } else if (item.offline === '0') {
          itemName += item.offlineTimeStr ? '离线(' + item.offlineTimeStr + ')' : '离线'
        } else {
          itemName += '未安装'
        }
        return itemName
      },
      driverSelect (value) {
        this.searchParams.driverId = value.split('_')[2]
      },
      loadUserList () {
        this.$http(this, {
          url: SERVICE_URLS.organization.orgUserTree,
          data: {
            authPrefix: '',
            chooseType: 'RADIO'
          },
          noTips: true,
          success: (data) => {
            this.orgUserTreeData = data.body
            if (this.searchParams.driverId) {
              var driverUserKey = this.getSelectKey(this.orgUserTreeData, this.searchParams.driver.id)
            }
            if (driverUserKey) {
              this.form.setFieldsValue({
                'driver.id': driverUserKey
              })
            }
          }
        })
      },
      getSelectKey (list, id) {
        var key
        for (let i = 0; i < list.length; i++) {
          const item = list[i]
          const tempKey = item.key
          if (tempKey.substring('USER_') !== -1 && Number(tempKey.split('_')[2]) === id) {
            key = tempKey
            return key
          } else if (item.children.length > 0) {
            key = this.getSelectKey(item.children, id)
          }
          if (key) return key
        }
      },
      loadStatistics () {
        this.$http(this, {
          url: SERVICE_URLS.csgz.carInfoApi.statistics,
          data: {
            carInfo: this.searchParams.carId,
            driver: this.searchParams.driverId,
            startTime: this.searchParams.startTime,
            endTime: this.searchParams.endTime
          },
          noTips: true,
          success: (data) => {
            this.statisticsData = data.body
          }
        })
      },
      loadListData () {
        this.searchParams.carInfoId = this.searchParams.carId
        this.$http(this, {
          url: SERVICE_URLS.csgz.carLoopRecordApi.search,
          noTips: true,
          loading: 'tableLoading',
          data: this.searchParams,
          success: (data) => {
            this.listData = data.body.content
            this.pagination.total = data.body.totalElements
            this.pagination.current = data.body.number + 1
            this.pagination.pageSize = data.body.size
            this.searchParams.size = data.body.size
            this.searchParams.number = data.body.number
            this.searchParams.carInfoId = null
          }
        })
      },
      loadTimeGisTrail () {
        this.$http(this, {
          url: SERVICE_URLS.csgz.carGisApi.timeGisTrail,
          noTips: true,
          loading: 'loading',
          data: this.searchParams,
          success: (data) => {
            if (data.body.mileage > 0) {
              this.mileage = data.body.mileage
            } else {
              this.mileage = 0
            }
            if (data.messageCode === 'fetch.success') {
              this.drawLine(data.body.carInfo.id, data.body.trail)
            } else {
              this.$message.warning('未能从平台获取到目标轨迹！')
            }
          }
        })
      },
      loadDetailTrail (carId, id) {
        this.$http(this, {
          url: SERVICE_URLS.csgz.carLoopRecordApi.viewDetailAndImage,
          params: { id },
          noTips: true,
          success: (data) => {
            //Object.assign(this.formItem, data.body)
            // this.reportNames = eval(this.formItem.reportNames)
            // this.images = data.body.images
            if (data.body.recordTrail) {
              this.drawLoopRecordLine(carId, data.body.recordTrail.position)
            }
            if (data.body.carStops.length > 0) {
              this.drawCarStopMarks(data.body.carStops)
            }
          }
        })
      },
      drawCarStopMarks (carStops) {
        carStops.forEach((x) => {
          // 创建 AMap.Icon 实例：
          var icon = new AMap.Icon({
            size: new AMap.Size(28, 28), // 图标尺寸
            image: '/images/icon_stop_spot.png', // Icon的图像
            imageSize: new AMap.Size(28, 28) // 根据所设置的大小拉伸或压缩图片
          })
          // 将 Icon 实例添加到 marker 上:
          var marker = new AMap.Marker({
            position: new AMap.LngLat(x.longitude, x.latitude),
            offset: new AMap.Pixel(-10, -26),
            title: this.getCarStopName(x),
            icon: icon,
            angle: 0,
            zoom: 15,
            extData: {
              key: x.id
            }
          })
          /*marker.setLabel({
            offset: new AMap.Pixel(0, 0), //设置文本标注偏移量
            content: '<div class="car-marker-info">' + '停车点' + '</div>', //设置文本标注内容
            direction: 'top' //设置文本标注方位
          })*/
          if ((x.longitude !== '0') && (x.latitude !== '0')) {
            this.carStopMarks.push(marker)
            this.detailMap.add(marker)
            this.detailMap.setFitView()
          }
        })
      },
      getCarStopName (item) {
        return '停留时长：' + item.startTime + '~' + item.startTime + ' (' + item.durationStr + ')\n' +
          '停留位置：' + item.location
      },
      onPageChange (page, pageSize) {
        this.searchParams.number = page - 1
        this.searchParams.size = pageSize
        this.loadListData()
      }
    }
  }
</script>

<style scoped>
  .key-value {
    font-weight: bold;
  }
</style>