<template>
  <div style="user-select: none;">
    <a-alert type="error" v-if="showErrorTips" :message="errorMessage" banner />
    <div :id="playerContainerId" :style="{ width: width + 'px', height: height + 'px' }"></div>
  </div>
</template>

<script>
/* eslint-disable */
import * as JsEncryptModule from 'jsencrypt'
import UUID from '../../../utils/UUID'

import HKPlayerResize from './common/hkplayer-resize'

import HKPlayerInitArgs from './common/hkplayer-init-args'

export default {
  name: 'HKPlayer',
  mixins: [HKPlayerResize],
  props: {
    width: {
      type: Number,
      default: 1044,
    },
    height: {
      type: Number,
      default: 600,
    },
    cameraIndexCodes: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      playerContainerId: new UUID().id,
      oWebControl: undefined,
      showErrorTips: false,
      errorMessage: '',
      pubKey: undefined,
      initSuccess: false,
      playInterval: undefined,
      initCount: 0,
      currentCameraIndexCodes: this.cameraIndexCodes,
      defaultInitArgs: {
        //API网关提供的appkey
        appkey: '27339437',
        //API网关提供的secret
        secretKey: 'zATVBCzPOAAiHtnWBzAz',
        //API网关IP地址
        ip: '202.100.100.50',
        // ip: '172.168.101.31',
        //播放模式（决定显示预览还是回放界面）
        playMode: 0,
        //端口
        port: 4443,
        //抓图存储路径
        snapDir: 'D:\\SnapDir',
        //紧急录像或录像剪辑存储路径
        videoDir: 'D:\\VideoDir',
        //布局
        layout: '1x1',
        //是否启用HTTPS协议
        enableHTTPS: 1,
        //加密字段
        encryptedFields: 'secret',
        //是否显示工具栏
        showToolbar: 1,
        //是否显示智能信息
        showSmart: 1,
        //自定义工具条按钮
        buttonIDs: '0,256,257,258,259,260,512,513,514,515,516,517,768,769',
      },
    }
  },
  watch: {
    cameraIndexCodes(value) {
      this.currentCameraIndexCodes = value
    },
  },
  methods: {
    initPlugin() {
      const { playerContainerId, width, height } = this
      this.oWebControl = new WebControl({
        szPluginContainer: this.playerContainerId, // 指定容器id
        iServicePortStart: 15900, // 指定起止端口号，建议使用该值
        iServicePortEnd: 15909,
        szClassId: '23BF3B0A-2C56-4D97-9C03-0CB103AA8F11', // 用于IE10使用ActiveX的clsid
        cbConnectSuccess: () => {
          // 创建WebControl实例成功
          this.oWebControl
            .JS_StartService('window', {
              // WebControl实例创建成功后需要启动服务
              dllPath: './VideoPluginConnect.dll', // 值"./VideoPluginConnect.dll"写死
            })
            .then(
              () => {
                // 启动插件服务成功
                this.oWebControl.JS_SetWindowControlCallback({
                  // 设置消息回调
                  cbIntegrationCallBack: this.cbIntegrationCallBack,
                })

                this.oWebControl.JS_CreateWnd(playerContainerId, width, height).then(() => {
                  //JS_CreateWnd创建视频播放窗口，宽高可设定
                  this.init() // 创建播放实例成功后初始化
                })
              },
              () => {
                // 启动插件服务失败
                this.showErrorTipsHandle('视频播放插件启动失败，未知错误！')
              }
            )
        },
        cbConnectError: () => {
          // 创建WebControl实例失败
          this.oWebControl = undefined
          this.showErrorTipsHandle('插件未启动，正在尝试启动，请稍候...')
          WebControl.JS_WakeUp('VideoWebPlugin://') // 程序未启动时执行error函数，采用wakeup来启动程序
          this.initCount++
          if (this.initCount < 3) {
            setTimeout(() => {
              this.initPlugin()
            }, 3000)
          } else {
            this.showErrorTipsHandle('插件启动失败，请检查插件是否安装！（安装插件后，请刷新当前页面）')
          }
        },
        cbConnectClose: function (bNormalClose) {
          // 异常断开：bNormalClose = false
          // JS_Disconnect正常断开：bNormalClose = true
          this.oWebControl = undefined
        },
      })
    },

    init() {
      const { width, height } = this
      this.getPubKey(() => {
        const args = Object.assign(HKPlayerInitArgs, this.defaultInitArgs)
        args.secret = this.setEncrypt(this.defaultInitArgs.secretKey)
        //通过传入设备，设置播放窗口布局
        args.layout = this.getHKPlayLayout()
        this.oWebControl
          .JS_RequestInterface({
            funcName: 'init',
            argument: JSON.stringify(args),
          })
          .then((oData) => {
            this.oWebControl.JS_Resize(width, height) // 初始化后resize一次，规避firefox下首次显示窗口后插件窗口未与DIV窗口重合问题
            this.initSuccess = true
          })
      })
    },

    /**
     * 播放
     */
    play() {
      this.clearPlayInterval()
      // 验证是否已经启动插件
      if (!this.oWebControl) {
        this.initPlugin()
        // 设置计时器 1s执行一次 验证初始化是否执行成功，
        this.playInterval = setInterval(() => {
          if (this.initSuccess) {
            this.clearPlayInterval()
            this.startPlay()
          }
        }, 1000)
      } else {
        // 需要判断播放流数量是否一致，不一致需要重设布局
        setTimeout(() => {
          this.setLayout()
          this.showWnd()
          this.startPlay()
        }, 300)
      }
    },
    setLayout() {
      this.oWebControl.JS_RequestInterface({
        funcName: 'setLayout',
        argument: JSON.stringify({
          layout: this.getHKPlayLayout(),
        }), // 窗口布局
      })
    },
    startPlay() {
      this.currentCameraIndexCodes.forEach((x, index) => {
        var cameraIndexCode = x.cameraIndexCode //获取输入的监控点编号值，必填
        var streamMode = 0 //主子码流标识：0-主码流，1-子码流
        var transMode = 1 //传输协议：0-UDP，1-TCP
        var gpuMode = 0 //是否启用GPU硬解，0-不启用，1-启用
        var wndId = index + 1 //播放窗口序号（在2x2以上布局下可指定播放窗口）

        cameraIndexCode = cameraIndexCode.replace(/(^\s*)/g, '')
        cameraIndexCode = cameraIndexCode.replace(/(\s*$)/g, '')

        this.oWebControl.JS_RequestInterface({
          funcName: 'startPreview',
          argument: JSON.stringify({
            cameraIndexCode: cameraIndexCode, //监控点编号
            streamMode: streamMode, //主子码流标识
            transMode: transMode, //传输协议
            gpuMode: gpuMode, //是否开启GPU硬解
            wndId: wndId, //可指定播放窗口
          }),
        })
      })
    },

    /**
     * 显示错误提示
     */
    showErrorTipsHandle(message) {
      this.showErrorTips = true
      this.errorMessage = message
    },

    /**
     * 消息回调
     */
    cbIntegrationCallBack(oData) {
    //  console.log(oData)
      /*showCBInfo(JSON.stringify(oData.responseMsg))*/
    },
    /**
     * 获取公钥
     * @param callback
     */
    getPubKey(callback) {
      this.oWebControl
        .JS_RequestInterface({
          funcName: 'getRSAPubKey',
          argument: JSON.stringify({
            keyLength: 1024,
          }),
        })
        .then((oData) => {
          if (oData.responseMsg.data) {
            this.pubKey = oData.responseMsg.data
            callback()
          }
        })
    },
    /**
     * RSA加密
     * @param value
     */
    setEncrypt(value) {
      var encrypt = new JsEncryptModule.JSEncrypt()
      encrypt.setPublicKey(this.pubKey)
      return encrypt.encrypt(value)
    },

    /**
     * 关闭播放链接
     */
    disconnect() {
      if (this.oWebControl != null) {
        // this.oWebControl.JS_HideWnd() // 先让窗口隐藏，规避可能的插件窗口滞后于浏览器消失问题
        this.oWebControl.JS_Disconnect().then(
          () => {
            // 断开与插件服务连接成功
            console.log('断开与插件服务连接成功')
          },
          () => {
            // 断开与插件服务连接失败
            console.log('断开与插件服务连接失败')
          }
        )
      }
    },

    /**
     * 清楚播放监听任务
     */
    clearPlayInterval() {
      if (this.playInterval) {
        clearInterval(this.playInterval)
        this.playInterval = undefined
      }
    },

    /**
     * 获取播放器layout
     */
    getHKPlayLayout() {
      const length = this.currentCameraIndexCodes.length
      switch (length) {
        case 0:
          return '1x1'
        case 1:
          return '1x1'
        case 2:
          return '1x2'
        case 3:
          return '1+2'
        case 4:
          return '2x2'
        case 5:
          return '1x4'
        case 6:
          return '1+5'
        case 7:
          return '3+4'
        case 8:
          return '1+7'
        case 9:
          return '3x3'
        case 10:
          return '1+9'
        default:
          return '4x4'
      }
    },

    /**
     * 弹窗关闭
     */
    closeHandle() {
      this.clearPlayInterval()
      if (this.oWebControl) {
        this.hideWnd()
        // 停止所有预览
        this.oWebControl.JS_RequestInterface({
          funcName: 'stopAllPreview',
        })
      }
    },
    /**
     * 显示播放窗口
     */
    showWnd() {
      this.oWebControl.JS_ShowWnd()
    },
    /**
     * 隐藏播放窗口
     */
    hideWnd() {
      this.oWebControl.JS_HideWnd()
    },
  },
  beforeDestroy() {
    this.disconnect()
    this.clearPlayInterval()
  //  console.log('>>>>>>beforeDestroy')
  },
}
</script>
