<template>
  <a-spin :spinning="spinShow">
    <div class="form-search-body" style="padding:20px;">
      <div class="center" style="margin-bottom: 10px;">
        <a-radio-group defaultValue="ALL" buttonStyle="solid" @change="onSearch" v-model="searchParams.completeStatus" size="small">
          <a-radio-button value="ALL">全部</a-radio-button>
          <a-radio-button value="NOTOPEN">未审核</a-radio-button>
          <a-radio-button value="OPTIONAL">待接单</a-radio-button>
          <a-radio-button value="CHECKED">已接单</a-radio-button>
          <a-radio-button value="DOING">清运中</a-radio-button>
        </a-radio-group>
      </div>
      <a-list :bordered="false" :dataSource="dataSource" style="font-size: 13px;">
        <a-list-item style="cursor: pointer" class=" default-hover" v-if="searchParams.completeStatus==='ALL'"
                     slot="renderItem"
                     @click.native="itemClick(item)"
                     slot-scope="item">
          <a-col :span="2" v-if="item.type === spotTypeEnum.SANGE.value">
            <img src="https://sosfile.fangshuoit.com/o_1efttl4ca1lo2fvq54aqtev971c.png">
          </a-col>
          <a-col :span="2" v-if="item.type === spotTypeEnum.DANWENG.value">
            <img src="https://sosfile.fangshuoit.com/o_1ec257ofroi51rk916c71s351t7k1h.png">
          </a-col>
          <a-col :span="2"
                 v-if="item.type === spotTypeEnum.JIWUCHI.value || item.type === spotTypeEnum.LANI.value || item.type === spotTypeEnum.GONGCE.value || item.type === spotTypeEnum.OTHER.value">
            <img src="https://sosfile.fangshuoit.com/o_1ec254c721l1n52rt4rdd9s879.png">
          </a-col>
          <a-col :span="11">{{ item.name }}</a-col>
          <a-col :span="11" style="text-align: right;">
            {{ item.areaName }}
          </a-col>
        </a-list-item>
        <a-list-item style="cursor: pointer" class=" default-hover"
                     slot="renderItem" v-else
                     @click.native="itemClick(item.spot)"
                     slot-scope="item">
          <a-col :span="2" v-if="item.spot.type === spotTypeEnum.SANGE.value">
            <img src="https://sosfile.fangshuoit.com/o_1efttl4ca1lo2fvq54aqtev971c.png">
          </a-col>
          <a-col :span="2" v-if="item.spot.type === spotTypeEnum.DANWENG.value">
            <img src="https://sosfile.fangshuoit.com/o_1ec257ofroi51rk916c71s351t7k1h.png">
          </a-col>
          <a-col :span="2"
                 v-if="item.spot.type === spotTypeEnum.JIWUCHI.value || item.spot.type === spotTypeEnum.LANI.value || item.spot.type === spotTypeEnum.GONGCE.value || item.spot.type === spotTypeEnum.OTHER.value">
            <img src="https://sosfile.fangshuoit.com/o_1ec254c721l1n52rt4rdd9s879.png">
          </a-col>
          <a-col :span="11">{{ item.spot.name }}</a-col>
          <a-col :span="11" style="text-align: right;">
            {{ item.spot.areaName }}
          </a-col>
        </a-list-item>
        <div slot="footer">
          <a-pagination
            size="small"
            :current="pagination.current"
            :pageSize="pagination.pageSize"
            :total="pagination.total"
            :showTotal="pagination.showTotal"
            @change="onPageChange"
          />
        </div>
      </a-list>
    </div>
  </a-spin>
</template>

<script>
import SERVICE_URLS from '@/api/service.url'
import searchListCommon from '../mixins/search-list-common'
import { spotTypeEnum } from '@/constant/spot/spotType'

export default {
  name: 'SearchSpotList',
  props: {},
  mixins: [searchListCommon],
  data () {
    return {
      searchParams: {
        number: '0',
        size: '10',
        direction: '',
        order: '',
        completeStatus: 'ALL', //集污点清运状态
        name: '' //集污点名称
      },
      spotSearchParams: {
        page: '0',
        size: '10',
        direction: '',
        order: '',
        name: '' //集污点名称
      },
      pagination: {
        current: 1,
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `第 ${range[0]}-${range[1]} 条 共${total}记录`
      },
      dataSource: [],
      listType: 'spot',
      spotTypeEnum: spotTypeEnum,
      gatherSpot: 'ALL'
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    handleCheckLayerType (value) {
      this.gatherSpot = value
    },
    /**
     * 执行数据加载
     */
    loadData () {
      if (this.spinShow) return
      this.$emit('onSearchStart')
      if (this.searchParams.completeStatus === 'ALL') {
        this.$http(this, {
          url: SERVICE_URLS.csgz.gatherSpot.search,
          data: this.spotSearchParams,
          noTips: true,
          loading: 'spinShow',
          success: (data) => {
            this.dataSource = data.body.content
            this.pagination.total = data.body.totalElements
            this.pagination.current = this.spotSearchParams.page + 1
            this.$emit('onSearchSuccess')
          },
          error: () => {
            this.$emit('onSearchError')
            this.$message.error('集污点列表检索失败！')
          }
        })
      } else {
        this.$http(this, {
          url: SERVICE_URLS.csgz.reportRecord.search,
          data: this.searchParams,
          noTips: true,
          loading: 'spinShow',
          success: (data) => {
            this.dataSource = data.body.content
            this.pagination.total = data.body.totalElements
            this.pagination.current = this.searchParams.number + 1
            this.$emit('onSearchSuccess')
          },
          error: () => {
            this.$emit('onSearchError')
            this.$message.error('集污点列表检索失败！')
          }
        })
      }
    },

    /*分页改变*/
    onPageChange (page, pageSize) {
      if (this.searchParams.completeStatus === 'ALL') {
        this.spotSearchParams.page = page - 1
        this.spotSearchParams.size = pageSize
      } else {
        this.searchParams.number = page - 1
        this.searchParams.size = pageSize
      }
      // this.searchParams.number = page - 1
      // this.searchParams.size = pageSize
      this.loadData()
    },
    /**
     * 重置分页信息
     */
    resetPage () {
      if (this.searchParams.completeStatus === 'ALL') {
        this.spotSearchParams.page = 0
      } else {
        this.searchParams.number = 0
      }
    },

    /**
     * searchKey发生变化 watch会执行当前方法
     * @param value 变化值
     */
    searchKeyChange (value) {
      if (this.searchParams.completeStatus === 'ALL') {
        this.spotSearchParams.name = value
      } else {
        this.searchParams.name = value
      }
    },

    /**
     * 列表点击事件
     * @param item 当前列值
     */
    itemClick (item) {
      this.$emit('onItemClick', this.listType, item)
    }
  }
}
</script>
<style scoped>
img {
  width: 20px;
  height: 20px;
}
</style>
