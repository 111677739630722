<template>
  <right-form-container :title="' 车辆信息'" v-model="visible" @close="close">
    <a-spin :spinning="ifSub" :delay="200">
      <div v-malihu-scrollbar class="mCustomScrollbar" style="overflow-y: auto;" :style="{ 'max-height': bodyHeight + 'px' }">
        <div class="p-12">
          <a-form layout="inline">
            <a-row>
              <a-form-item label="所属部门">
                <span>{{ formItem.carInfo.department.name || '-' }}</span>
              </a-form-item>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-item label="车辆司机" style="margin-right: 0;">
                  <span>{{((formItem.driver&&formItem.driver.name)?formItem.driver.name:'') || '' }} </span>
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="司机电话" style="margin-right: 0;">
                  <span>{{ ((formItem.mobilePhoneNumber&&formItem.driver.mobilePhoneNumber)?formItem.driver.mobilePhoneNumber:'') || '' }}</span>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-item label="车辆载重" style="margin-right: 0;">
                  <span>{{ formItem.carInfo.volume || '' }} 吨</span>
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="车辆速度" style="margin-right: 0;">
                  <span>{{ formItem.carInfo.speed }} km/h</span>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24">
                <a-form-item label="当前位置" style="margin-right: 0;">
                  <span>{{ formItem.carInfo.locationInfo || '' }}</span>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <div class="p-12 p-t-12">
          <a-row :gutter="16">
            <a-col :span="12">
              <metrics value="清运次数"><span slot="title">{{ statisticsData.records || '0' }} 次</span></metrics>
            </a-col>
            <a-col :span="12">
              <metrics value="清运公里"><span slot="title">{{ statisticsData.mileages ? statisticsData.mileages.toFixed(2) : '0'  }} km</span></metrics>
            </a-col>
          </a-row>
          <a-row :gutter="16" class="m-t-12">
            <a-col :span="12">
              <metrics value="清运时长"><span slot="title">{{ statisticsData.durations || '0' }}</span></metrics>
            </a-col>
            <a-col :span="12">
              <metrics value="清运吨数"><span slot="title">{{ statisticsData.tons || '0' }} 吨</span></metrics>
            </a-col>
          </a-row>
        </div>
      </div>
      <a-divider style="margin: 10px 0 6px 0;"></a-divider>
      <div class="p-12 p-b-20">
        <a-row :gutter="20">
          <a-col :span="12">
            <a-button type="primary" block @click="showHistoryTrail">历史轨迹</a-button>
          </a-col>
          <a-col :span="12">
            <a-button type="primary" block @click="handleLoopHistory">清运记录</a-button>
          </a-col>
        </a-row>
      </div>
    </a-spin>
    <!--车辆历史轨迹-->
    <a-modal title="历史轨迹" :width="1600" centered :footer="null" :visible="historyTrailModal" :destroyOnClose="true" @cancel="closeHistoryTrailModal">
      <history-track ref="historyTrailRef"></history-track>
    </a-modal>
    <!--车辆清运历史Model-->
    <a-modal title="车辆历史清运任务" :width="1500" centered :footer="null" :visible="loopHistoryModal" @cancel="closeLoopHistoryModal">
      <history-list ref="loopHistoryRef"></history-list>
    </a-modal>
  </right-form-container>
</template>

<script>
  import SERVICE_URLS from '@/api/service.url'
  import { carInfoFormVO } from '../../car/info/common/carInfoFormVO'
  import containerClose from '.././mixins/container-close'
  import RightFormContainer from './RightFormContainer'
  import { mapState } from 'vuex'
  import HistoryTrack from '../../car/info/HistoryTrack'
  import moment from 'moment'
  import HistoryList from '../../car/info/HistoryList'
  import Metrics from './Metrics'

  export default {
    name: 'MapCarInfoMapDetail',
    components: { HistoryTrack, HistoryList, RightFormContainer, Metrics },
    mixins: [containerClose],
    data () {
      return {
        carId: null,
        ifSub: false,
        currentItem: {
          name: ''
        },
        formItem: carInfoFormVO(),
        /*弹框*/
        historyTrailModal: false,
        loopHistoryModal: false,
        statisticsData: {}
      }
    },
    computed: {
      ...mapState({
        bodyHeight: (state) => {
          return state.app.bodyHeight - 200
        }
      })
    },
    methods: {
      moment,
      loadData (id) {
        this.formItem = carInfoFormVO()
        this.carId = id
        this.initDate(id)
      },
      initDate (id) {
        if (id) {
          this.$http(this, {
            url: SERVICE_URLS.csgz.carInfoApi.viewAll,
            params: { id: id },
            noTips: true,
            success: (data) => {
              Object.assign(this.formItem, data.body)
              this.currentItem.name = this.formItem.carInfo.platNumber
            }
          })
          this.loadCarStatistics()
        }
      },
      handleLoopHistory () {
        this.loopHistoryModal = true
        this.$nextTick(() => {
          this.$refs['loopHistoryRef'].searchRecord(this.carId)
        })
      },
      closeLoopHistoryModal () {
        this.loopHistoryModal = false
      },
      /*历史轨迹*/
      showHistoryTrail () {
        this.historyTrailModal = true
        this.$nextTick(() => {
          this.$refs['historyTrailRef'].selectCarInfo(this.carId)
        })
      },
      closeHistoryTrailModal () {
        this.historyTrailModal = false
      },
      loadCarStatistics () {
        this.$http(this, {
          url: SERVICE_URLS.csgz.carInfoApi.statistics,
          data: {
            carInfo: this.carId
          },
          noTips: true,
          success: (data) => {
            this.statisticsData = data.body
          }
        })
      }
    }
  }
</script>
