import SERVICE_URLS from '../../../../api/service.url'
import { spotTypeEnum } from '@/constant/spot/spotType'

const spot = {
  data () {
    return {
      layerType: [
        {
          label: '全部',
          value: 'ALL'
        },
        {
          label: '未审核',
          value: 'NOTOPEN'
        },
        {
          label: '待接单',
          value: 'OPTIONAL'
        },
        {
          label: '已接单',
          value: 'CHECKED'
        },
        {
          label: '清运中',
          value: 'DOING'
        }
      ],
      checkLayer: 'ALL',
      gatherSpotType: [
        {
          label: '全部',
          value: 'ALL'
        },
        {
          label: '三格化粪池',
          value: 'SANGE'
        },
        {
          label: '集污池',
          value: 'JIWUCHI'
        },
        {
          label: '单翁',
          value: 'DANWENG'
        },
        {
          label: '拉泥',
          value: 'LANI'
        },
        {
          label: '公厕',
          value: 'GONGCE'
        },
        {
          label: '其他',
          value: 'OTHER'
        }
      ],
      gatherSpot: 'ALL',
      qyType: 'task',
      loadLayerData: false,
      qySpotList: [],
      qySpotMap: {},
      dropdpwnVisible: false,
      allMassMarkerData: [], //所有集污点数据
      allMassMarkerMap: {},  //所有集污点Map数据
      massMarkData: [], //加载的集污点数据
      markerIconWidthHeight: 20,  //marker宽高
      titleMarker: null,
      aMapMassMarkManager: null, //海量数据操作类
      districtList: [
        {
          children: [],
          label: '全部',
          value: '0'
        }
      ],
      districtConfigtMap: {},
      areaTreeVisible: false,
      areaTreeKey: [],
      selectedAreaCode: '0',
      areaTreeLabel: null,
      areaTreeExpandedKeys: [],
      areaTreeDefaultSelectedKeys: [],
      areaType: [
        {
          label: '全部',
          value: 'ALL'
        },
        {
          label: '区、县',
          value: 'area'
        },
        {
          label: '镇',
          value: 'town'
        },
        {
          label: '村',
          value: 'village'
        }
      ],
      areaTypeValue: 'area',
      carType: [
        {
          label: '显示',
          value: '0'
        },
        {
          label: '隐藏',
          value: '1'
        }
      ],
      carTypeValue: '0'
    }
  },
  methods: {
    //有任务的  任务状态选择
    handleCheckLayerType (value) {
      // this.checkLayer = value
      this.loadMassMarkers()
    },
    //集污点类型选择
    handleGatherSpotType (value) {
      this.loadMassMarkers()
    },
    //清运状态选择
    handleQyType (val) {
      this.qyType = val
      this.loadMassMarkers()
    },
    /*初始化加载所有集污点信息*/
    getSpot () {
      this.$http(this, {
        url: SERVICE_URLS.csgz.reportRecord.list,
        data: {
          bindStatus: 'ALL'
        },
        loading: 'loadLayerData',
        noTips: true,
        success: (data) => {
          this.qySpotList = data.body
          this.qySpotMap = {}
          this.qySpotList.forEach((x) => {
            this.qySpotMap[x.spot.id] = x
          })
          this.loadMassMarkers(this.qySpotList)
        },
        error: () => {
        }
      })
    },
    // 获取未清运过的集污点
    getAllSpot () {
      this.$http(this, {
        url: SERVICE_URLS.csgz.gatherSpot.selectAll,
        data: {
          spotType: null
        },
        noTips: true,
        loading: 'spinShow',
        success: (data) => {
          let arr1 = data.body
          arr1.map((item1, i) => {
            let item = {
              type: item1.t,
              name: item1.n,
              areaNo: item1.o,
              longitude: item1.l,
              latitude: item1.a,
              id: item1.i,
              monitorPointName: item1.monitorPointName,
              monitorPointNo: item1.monitorPointNo
            }
            if (item.longitude !== null &&
              item.latitude !== null &&
              item.longitude !== 0 &&
              item.latitude !== 0) {
              item.lnglat = [item.longitude, item.latitude]
              item.style = this.getMassMarkerStyleIndex(item.type)
              let indexA = this.allMassMarkerData.length
              this.allMassMarkerData[indexA] = item
              this.allMassMarkerMap[item.id] = item
            }
          })
          this.initMassMarkManager()
          this.loadMassMarkers()
        },
        error: () => {
        }
      })
    },
    //加载海量数据
    loadMassMarkers () {
      //海联管理对象初始化之后才能 做加载数据
      if (this.aMapMassMarkManager) {
        this.massMarkData = []
        if (this.qyType === 'task') { //有任务
          this.qySpotList.forEach((x) => {
            let spot = this.allMassMarkerMap[x.spot.id]
            if (spot && this.judgeBindStatus(x) && this.judgeSpotType(spot) && this.judgeArea(spot)) {
              this.massMarkData.push(this.allMassMarkerMap[x.spot.id])
            }
          })
        } else if (this.qyType === 'noTask') { //无任务
          this.allMassMarkerData.forEach((x) => {
            if (!this.qySpotMap[x.id]) {
              if (this.judgeSpotType(x) && this.judgeArea(x)) {
                this.massMarkData.push(x)
              }
            }
          })
        } else if (this.qyType === 'ALL') { //所有
          this.allMassMarkerData.forEach((x) => {
            if (this.judgeSpotType(x) && this.judgeArea(x)) {
              this.massMarkData.push(x)
            }
          })
        }
        this.aMapMassMarkManager.setData(this.massMarkData)
      }
    },
    judgeBindStatus (record) {
      if (!record) {
        return false
      }
      if (this.checkLayer === 'ALL' || this.checkLayer === record.bindStatus) {
        return true
      }
      return false
    },
    judgeSpotType (spot) {
      if (!spot) {
        return false
      }
      if (this.gatherSpot === 'ALL' || spot.type === this.gatherSpot) {
        return true
      }
      return false
    },
    judgeArea (spot) {
      if (this.selectedAreaCode === '0' || this.selectedAreaCode === 0) {
        return true
      }
      let searNo = this.getDistrictBaseNo()
      let spotAreaNo = spot.areaNo
      if (this.startWith(spotAreaNo, searNo)) {
        return true
      }
      return false
    },
    getDistrictBaseNo () {
      if (this.selectedAreaCode === '0') {
        return '0'
      }
      let searNo = this.selectedAreaCode
      if (this.endWith(this.selectedAreaCode, '0000')) { //县
        searNo = this.selectedAreaCode.slice(0, 2)
      } else if (this.endWith(this.selectedAreaCode, '00')) { //镇
        searNo = this.selectedAreaCode.slice(0, 4)
      }
      return searNo
    },
    //初始化华联管理对象
    initMassMarkManager () {
      this.aMapMassMarkManager = new AMap.MassMarks([], {
        zIndex: 111,  // 海量点图层叠加的顺序
        zooms: [3, 20],  // 在指定地图缩放级别范围内展示海量点图层
        style: this.getMassMarkerStyles(),
        alwaysRender: false
      })
      this.titleMarker = new AMap.Marker({ content: ' ', map: this.map })
      var me = this
      this.aMapMassMarkManager.on('mouseover', function (e) {
        me.setOverMarkerStyle(e.data)
      })
      this.aMapMassMarkManager.on('click', function (e) {
        me.loadRightDetail('spot', e.data)
        me.setSelectedMarkerStyle(e.data.lnglat)
      })
      this.aMapMassMarkManager.setMap(this.map)
    },
    spotItemClickHandle (type, item) {
      this.dropdpwnVisible = false
      this.spotFixed(item)
      this.loadRightDetail(type, item)
    },
    loadRightDetail (type, item) {
      this.rightFormChecked = type
      this.rightFormShow = true
      this.areaTreeVisible = false
      this.dropdpwnVisible = false
      this.$nextTick(() => {
        this.getRightDetailRef().loadData(item)
      })
    },
    spotFixed (item) {
      this.map.setCenter([item.longitude, item.latitude])
      this.map.setZoom(17)
      this.setOverMarkerStyle(item)
      this.setSelectedMarkerStyle([item.longitude, item.latitude])
    },
    // 设置集污点over marker样式 显示titile
    setOverMarkerStyle (item) {
      if (this.titleMarker) {
        this.titleMarker.setPosition([item.longitude, item.latitude])
        this.titleMarker.setLabel({ content: item.name })
        this.titleMarker.setOffset(new AMap.Pixel(this.markerIconWidthHeight, 0))
      }
    },
    getMassMarkerStyles () {
      let widthHeight = this.markerIconWidthHeight
      let anchorLeft = 0
      let anchorRight = 0
      let styles = [{
        url: 'http://srcp.fangshuoit.com/o_1efttnai84jg1kks15u2c3iijc1m.png',  // 图标地址
        size: new AMap.Size(widthHeight, widthHeight),     // 图标大小
        anchor: new AMap.Pixel(anchorLeft, anchorRight) // 图标显示位置偏移量，基准点为图标左上角
      }, {
        url: 'http://srcp.fangshuoit.com/o_1efttnu7e1o1nkj31gi63rregv1r.png',  // 图标地址
        size: new AMap.Size(widthHeight, widthHeight),     // 图标大小
        anchor: new AMap.Pixel(anchorLeft, anchorRight) // 图标显示位置偏移量，基准点为图标左上角
      }, {
        url: 'http://srcp.fangshuoit.com/o_1efttl4ca1lo2fvq54aqtev971c.png',  // 图标地址
        size: new AMap.Size(widthHeight, widthHeight),     // 图标大小
        anchor: new AMap.Pixel(anchorLeft, anchorRight) // 图标显示位置偏移量，基准点为图标左上角
      }]
      return styles
    },
    getMassMarkerStyleIndex (spotType) {
      let styleIndex = 0
      if (spotType === spotTypeEnum.DANWENG.value) {
        styleIndex = 0
      }
      if (spotType === spotTypeEnum.JIWUCHI.value) {
        styleIndex = 1
      }
      if (spotType === spotTypeEnum.SANGE.value) {
        styleIndex = 2
      }
      return styleIndex
    },
    getAreaTree () {
      this.$http(this, {
        url: SERVICE_URLS.csgz.areaDictionaryApi.cascade,
        data: {
          bindStatus: 'ALL'
        },
        loading: 'loadLayerData',
        noTips: true,
        success: (data) => {
          this.areaTreeDefaultSelectedKeys = []
          this.areaTreeExpandedKeys = []
          this.districtList[0].children = data.body
          this.areaTreeExpandedKeys.push('0')
          this.areaTreeDefaultSelectedKeys.push('0')
        },
        error: () => {
        }
      })
    },
    onExpand (expandedKeys) {
      this.areaTreeExpandedKeys = expandedKeys
    },
    treeSelect (selectedKeys, e) {
      this.areaTreeVisible = false
      if (selectedKeys.length > 0) {
        this.areaTreeLabel = e.node.label
        this.areaTreeKey = selectedKeys
        this.selectedAreaCode = this.areaTreeKey[0]
        if (this.handleCheckDistrictLayerType) {
          if (this.selectedAreaCode === '0') {
            this.handleCheckDistrictLayerType('layerAll')
          } else {
            this.handleCheckDistrictLayerType('6401' + this.selectedAreaCode.slice(0, 2))
          }
        }
        this.loadMassMarkers()
        this.loadAreaInfoFromLayer(this.getDistrictBaseNo())
      }
    },
    endWith (str, endStr) {
      let reg = new RegExp(endStr + '$')
      return reg.test(str)
    },
    startWith (str, startStr) {
      let reg = new RegExp('^' + startStr)
      return reg.test(str)
    },
    handAreaType (e) {
      let t = e.target.value
      this.areaTypeValue = t
      for (let key in this.districtOverlayGroups) {
        this.districtOverlayGroups[key].hide()
      }
      if (t === 'ALL') {
        for (let key in this.districtOverlayGroups) {
          this.districtOverlayGroups[key].show()
        }
      } else {
        this.districtOverlayGroups[t].show()
      }
    }
  }
}
export default spot
